@import "src/scss/styles";


.analytics {
  position: relative;
  background-color: #111111;
  margin: 0 auto;

  display: flex;

  &--secondary {
    .analytics__schedule {
      flex-direction: column;
    }

    .analytics__schedule-content {
      width: 100%;
    }
  }
}

.analytics__inner {
  display: flex;
  flex-direction: column;
  gap: 42px;

  width: 100%;
  height: 100%;
  padding: 82px 74px;

  @media screen and #{$xxxl} {
    padding: 42px 60px;
  }

  @include tablet {
    padding: 42px 24px;
  }

  @include phone {
    gap: 24px;
  }
}

.analytics__title {
  font-weight: 500;
  font-size: 62px;
  line-height: 1.2;

  @include tablet {
    font-size: 52px;
  }

  @include phone {
    font-size: 24px;
  }
}

.analytics__description {
  font-size: 18px;

  @include phone {
    font-size: 14px;
    line-height: 1.28;
  }
}

.analytics__schedule {
  display: flex;
  justify-content: space-between;
  gap: 42px;

  @media screen and #{$xl} {
    flex-direction: column;
  }
}

.analytics__schedule-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  width: 38.5%;

  @media screen and #{$xl} {
    width: 100%;
  }
}

.analytics__schedule-description {
  font-size: 18px;

  @include phone {
    font-size: 14px;
    line-height: 1.28;
  }
}

.analytics__schedule-source {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.22;

  color: #A8A8A8;

  @include phone {
    font-size: 14px;
    line-height: 1.28;
  }
}

.analytics__image {
  img {
    @include cover-img;
  }
}

.analytics__info {
  padding: 24px 16px;

  font-size: 18px;

  border-left: 4px solid $violet-2;

  @include phone {
    font-size: 14px;
    line-height: 1.28;
  }
}

.analytics__info-additional {
  font-size: 18px;

  @include phone {
    font-size: 14px;
    line-height: 1.28;
  }
}
