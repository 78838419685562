@import "src/scss/styles";
.services {
  z-index: 0;
  position: relative;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.services__bg {
  position: absolute;
    z-index: -1;
    inset: 0;
    overflow: hidden;
    background: #111111;

    &::after {
      content: "";
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1800px;
      background: radial-gradient(93.39% 23.99% at 50% 0%, #2E1150 0%, rgba(134, 51, 233, 0) 64%);
      /* filter: blur(200px); */
    }
}

.services__inner {
  z-index: 2;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 96px;

  max-width: 1400px;
  padding: 94px 74px;

  @include desktop {
    padding: 82px 74px;
  }

  @include tablet {
    gap: 48px;
    padding: 64px 24px;
  }

  @include phone {
    gap: 32px;
    padding: 62px 24px;
  }
}

.services__top {
  display: flex;
  justify-content: space-between;

  @include desktop {
    flex-direction: column;
    gap: 48px;
  }

  @include phone {
    gap: 32px;
  }
}

.services__title {
  align-self: flex-end;

  @include desktop {
    align-self: flex-start;
  }
}

.services__img-wrap {
  @include desktop {
    width: 500px;
    height: 135px;
  }

  @include phone {
    width: 240px;
    height: 64px;
  }

  img {
    @include contain-img;
  }
}

.services__list {
  @include list-reset;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(18, auto);
  gap: 32px;

  @include desktop {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  li {
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1 / 11;
    }

    &:nth-child(2) {
      grid-column: 1;
      grid-row: 11 / 19;
    }

    &:nth-child(3) {
      grid-column: 2;
      grid-row: 1 / 8;
    }

    &:nth-child(4) {
      grid-column: 2;
      grid-row: 8 / 13;
    }

    &:nth-child(5) {
      grid-column: 2;
      grid-row: 13 / 19;
    }

    @include desktop {
      &:nth-child(1) {
        grid-column: 1;
        grid-row: auto;
      }

      &:nth-child(2) {
        grid-column: 1;
        grid-row: auto;
      }

      &:nth-child(3) {
        grid-column: 1;
        grid-row: auto;
      }

      &:nth-child(4) {
        grid-column: 1;
        grid-row: auto;
      }

      &:nth-child(5) {
        grid-column: 1;
        grid-row: auto;
      }
    }
  }
}

.service {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;

  height: 100%;
  padding: 48px 56px;

  background-color: #131214;

  @include phone {
    gap: 32px;
    padding: 32px 24px;
  }
}

.service__icon {
  .icon {
    width: 96px;
    height: 96px;
    object-position: center;
    object-fit: contain;
  }
}

.service__title {
  font-weight: 700;
  font-size: 24px;

  @include phone {
    font-size: 18px;
  }
}

.service__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.service__text {
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;

    color: $white;

    @include phone {
      font-size: 14px;
    }
  }
}

.service__list-wrap {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.service__list-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;

  @include phone {
    font-size: 16px;
  }
}

.service__list {
  @include list-reset;

  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    border-bottom: 1px solid rgba($white, 0.1);

    &:last-child {
      border-bottom: 0px;

      .privilege__content-item {
        padding-bottom: 0;
      }
    }
  }
}

.service__list-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  padding-bottom: 12px;

  .icon {
    color: $violet-2;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;

    color: $white;

    @include phone {
      font-size: 14px;
    }
  }
}

.service-info {
  position: relative;
  z-index: 1;

  transition: $base-transition;

  &:hover {
    .service-info__content {
      visibility: visible;
      opacity: 1;
    }
  }
}

.service-info__btn {
  cursor: pointer;
}

.service-info__content {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;

  transform: translateX(-50%);
  isolation: isolate;
  visibility: hidden;

  gap: 16px;

  width: 800px;
  padding: 16px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;

  border-radius: 12px;

  color: $black;
  background-color: $white;
  box-shadow: 0 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);

  opacity: 0;

  transition: $base-transition;

  @include desktop {
    max-width: 80vw;
  }

  @include tablet {
    font-size: 14px;
    line-height: 22px;
  }

  @include tablet-sm {
    left: -15vw;
    max-width: 60vw;
  }

  @include phone {
    left: -25vw;

    max-width: 75vw;
    padding: 12px;

    font-size: 12px;
    line-height: 20px;
  }

  b {
    font-weight: 600;
    letter-spacing: 0.15px;

    color: $black;
  }

  p {
    color: $black;
  }

  p:not(:last-child) {
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 12px;
    }

    @include phone {
      margin-bottom: 8px;
    }
  }
}
