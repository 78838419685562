@import "src/scss/styles";
.icon {
  flex-shrink: 0;
  display: block;

  width: 24px;
  height: 24px;
}

.icon svg {
  display: block;
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 0;
}
