@import "src/scss/styles";
.staff {
  @extend .container;

  background-color: $black;
  position: relative;
}

.staff__inner {
  display: flex;
  flex-direction: column;
  padding: 82px 0;
  row-gap: 42px;

  @include phone {
    padding: 62px 0;
  }
}

.staff__list {
  @include list-reset;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 18px;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include phone {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
