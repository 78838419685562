@import "src/scss/styles";
.staff-card {
    @extend .text-2;

    display: flex;
    flex-direction: column;
    row-gap: 16px;
    cursor: pointer;
}

.staff-card__image {
    display: block;
    object-fit: cover;
    margin-bottom: 10px;
    opacity: 0.8;
    width: 100%;

    height: 480px;

    @media screen and #{$xxl} {
        height: 380px;
    }

    @include desktop {
        height: 295px;
    }

    @include tablet {
        height: 505px;
    }

    @include phone {
        height: 544px;
    }
}

.staff-card__position {
    opacity: 0.5;
}

.staff-card__description {
    @include text-clamp(2);
    margin-top: auto;
}