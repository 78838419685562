$sm: "(max-width: 540px)";
$md: "(max-width: 768px)";
$lg: "(max-width: 1024px)";
$xl: "(max-width: 1280px)";
$xxl: "(max-width: 1440px)";
$xxxl: "(max-width: 1600px)";
$fhd: "(max-width: 1920px)";

$font-family: 'Montserrat', sans-serif;
$base-transition: 0.3s ease-in-out;

$mobile-width: 375px;
$desktop-width: 1728px;
$tablet-width: 768px;

$desktop-pd: 74px;
$mobile-pd: 24px;
