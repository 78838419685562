@import "src/scss/styles";
.m-insight {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 52px;
  
    @include tablet {
      gap: 52px;
    }
  
    @include phone {
      gap: 24px;
    }
  }
  
  .m-insight__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 1.2;
  
    @include tablet {
      font-size: 28px;
    }
  
    @include phone {
      font-size: 20px;
    }
  }
  
  .m-insight__info {
    overflow: hidden;
  
    display: flex;
    gap: 62px;
  
    max-height: 385px;
  
    @include desktop {
      gap: 32px;
    }
  
    @include tablet {
      flex-direction: column;
      gap: 52px;
  
      max-height: none;
    }
  
    @include phone {
      gap: 24px;
    }
  }
  
  .m-insight__video,
  .m-insight__image {
    flex-shrink: 0;
    opacity: 0.8;
    border-radius: 10px;
  
    width: 45%;
    height: 385px;
  
    @include tablet {
      width: 100%;
      height: 300px;
    }
  
    @include phone {
      height: 200px;
    }
  
    img {
      @include contain-img;
    }
  }
  
  .m-insight__text-wrap {
    overflow-y: auto;
  
    display: flex;
    flex-direction: column;
    gap: 16px;
  
    width: 100%;
    padding-right: 20px;
  
    @include scrollbar;
  
    &::-webkit-scrollbar-thumb {
      background-color: $violet-2;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #454545;
    }
  }
  
  .m-insight__text {
    display: flex;
    flex-direction: column;
    gap: 16px;
  
    width: 100%;
  
    font-size: 16px;
    line-height: 1.5;
  
    &--separator {
      text-align: center;
    }
  
    p {
      font-weight: 400;
    }
  
    b {
      font-weight: 700;
    }
  }