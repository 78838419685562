@import "src/scss/styles";


.commodities {
  position: relative;
  background-color: #111111;
  margin: 0 auto;

  display: flex;
}

.commodities__inner {
  display: flex;
  flex-direction: column;
  gap: 82px;

  width: 100%;
  height: 100%;
  padding: 82px 74px;

  @media screen and #{$xxxl} {
    padding: 42px 60px;
  }

  @include tablet {
    padding: 42px 24px;
  }

  @include phone {
    gap: 32px;
  }
}

.commodities__title {
  font-size: 62px;
  font-weight: 500;
  line-height: 1.2;

  @include tablet {
    font-size: 52px;
  }

  @include phone {
    font-size: 24px;
  }
}

.commodities__list {
  @include list-reset;

  display: flex;
  flex-direction: column;
  gap: 82px;

  @include phone {
    gap: 32px;
  }
}
