@import "src/scss/styles";
.questions__list {
  @include list-reset;

  display: flex;
  flex-direction: column;

  width: 100%;

  li {
    border-bottom: 1px solid $white;

    &:last-child {
      border-bottom: 0;
    }
  }
}
