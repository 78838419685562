@import "src/scss/styles";
.issuance {
  z-index: 0;
  position: relative;

  background-color: $black;
}

.issuance__inner {
  display: flex;
  flex-direction: column;
  gap: 40px;

  padding-bottom: 128px;

  @include phone {
    gap: 24px;
  }
}

.issuance__top {
  position: relative;

  display: flex;
  justify-content: center;

  padding: 74px 0;

  @include tablet {
    padding: 74px 0 12px;
  }

  @include phone {
    padding: 34px 0 22px;
  }
}

.issuance__title.h2 {
  font-weight: 400;
  text-align: center;

  @include tablet {
    max-width: 520px;
  }

  @include phone {
    max-width: 284px;
  }

  span {
    color: $violet-2;
  }
}

.issuance__bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 232px;
    background: radial-gradient(86.69% 100.43% at 50% 150.43%, rgba(120, 21, 237, 0.8) 0%, rgba(134, 51, 233, 0) 100%);
    background-blend-mode: screen;
  }
}

.issuance__content {
  display: flex;
  flex-direction: column;
  gap: 52px;

  padding: 0 74px;

  @include tablet {
    gap: 72px;
    padding: 0 24px;
  }

  @include tablet-sm {
    padding: 0;
  }

  @include phone {
    gap: 42px;
  }
}

.issuance__steps {
  @include list-reset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 54px;

  @include tablet {
    gap: 28px;
  }

  @include tablet-sm {
    @include no-scrollbar;
    overflow-x: scroll;
    overflow-y: hidden;

    padding-left: 24px;
  }

  li {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 54px;

    @include tablet {
      gap: 28px;
    }

    &:last-child {
      flex-grow: 0;
    }
  }
}

.issuance__steps-line {
  @include line-reset;

  width: 100%;
  height: 2px;

  background-color: rgba($violet-2, 0.5);

  &--active {
    background-color: rgba($violet-2, 1);
  }

  @include tablet-sm {
    flex-shrink: 0;
    width: 250px;
  }
}
