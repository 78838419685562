$white : #FFFFFF;
$black : #000000;

$dark: #212121;
$violet: #9C21E8;
$violet-l: #DBBFFF;
$violet-2: #DDC0FF;

$white-op-3: #FFFFFF4D;

$grey: #4E616B;

$error: #C10101;
