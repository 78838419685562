@import "src/scss/styles";


.partners {
  overflow: hidden;
  position: relative;

  display: flex;

  margin: 0 auto;

  background-color: $dark;
}

.partners__inner {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  padding: 82px 67px;
  gap: 72px;

  @media screen and #{$xl} {
    padding: 82px 74px;
  }

  @include tablet {
    gap: 52px;
    padding: 64px 24px;
  }
}

.partners__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    gap: 52px;
  }

  @include phone {
    gap: 24px;
  }
}

.partners__info {
  display: flex;
  flex-direction: column;
  gap: 32px;

  max-width: 500px;
  width: 100%;
}

.partners__info-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;

  @include phone {
    font-size: 16px;
  }
}

.partners__info-text {
  @include phone {
    font-size: 14px;
  }
}

.partners__image {
  align-self: flex-end;
  display: flex;

  width: 840px;
  height: 622px;

  @media screen and #{$xxxl} {
    width: 500px;
    height: 370px;
  }

  @media screen and #{$xl} {
    width: 380px;
    height: 280px;
  }

  @include tablet {
    align-self: center;
    width: 50%;
    height: 100%;
  }

  @include phone {
    width: 80%;

    padding: 0;
  }

  img {
    @include contain-img;
  }
}

.partners__bottom {
  overflow: visible;
  width: 100%;
  margin: 0;
}

.partners__list {
  @include list-reset;

  display: flex;
}

.partners__item {
  display: flex;
  width: 125px;
  height: 92px;

  filter: grayscale(1);
  cursor: pointer;

  transition: $base-transition;

  &:hover {
    filter: grayscale(0)
  }

  &.active {
    filter: grayscale(0)
  }

  img {
    @include contain-img;
  }
}
