@import "src/scss/styles";


.mining-main {
  display: flex;
  margin: 0 auto;

  min-height: calc(100vh - 84px);

  background-color: #282828;

  @media screen and #{$xl} {
    min-height: calc(100vh - 97px);
  }

  @include tablet {
    min-height: calc(100vh - 113px);
  }

  @include phone {
    min-height: calc(100vh - 73px);
  }
}

.mining-main__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;

  width: 100%;

  @media screen and #{$xxl} {
    flex-direction: column;
    align-items: stretch;

    padding-top: 82px;
  }

  @include tablet {
    gap: 32px;
  }

  @include phone {
    gap: 24px;
  }
}

.mining-main__content {
  display: flex;
  flex-direction: column;
  gap: 40px;

  max-width: 680px;
  margin-left: 74px;

  @media screen and #{$xxl} {
    max-width: none;
    margin: 0 74px;
  }

  @include tablet {
    margin: 0 24px;
  }

  @include phone {
    grid-area: 24px;
  }
}

.mining-main__title {
  font-weight: 500;
  font-size: 62px;
  line-height: 1.2;

  @include tablet {
    font-size: 52px;
  }

  @include phone {
    font-size: 24px;
  }
}

.mining-main__text {
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;

    @include tablet {
      font-size: 16px;
    }

    @include phone {
      font-size: 14px;
    }
  }
}

.mining-main__image {
  width: 45%;
  height: 100%;

  @media screen and #{$xxl} {
    width: 100%;
  }

  img {
    @include cover-img;
  }
}
