@import "src/scss/styles";


.banner {
  overflow: hidden;
  position: relative;
  z-index: 0;
  min-height: calc(100vh - 152px);
  margin: 0 auto;
  background-color: $black;

  @include tablet {
    min-height: calc(100vh - 221px);
  }

  @include phone {

  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    inset: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.banner__inner {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  padding: 52px 67px;

  @include tablet {
    padding: 82px 24px;
  }

  @include phone {
    padding: 52px 24px;
  }
}

.banner__title {
  max-width: 1220px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 62px;
  line-height: 76px;
  color: $white;

  @include tablet {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 63px;
  }

  @include phone {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }
}

.banner__text {
  max-width: 762px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $white;

  @include phone {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.banner__details {
  z-index: 2;
  transition: opacity $base-transition;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 14px 42px;

  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  border-radius: 10px;

  color: $black;
  background-color: $violet-2;

  @include phone {
    padding: 14px 20px;
  }

  @include hover {
    opacity: 0.8;
  }
}

.banner__image {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  img {
    @include cover-img;

    @media screen and #{$xl} {
      object-position: 0 0;
    }

    @include tablet {
      object-position: center;
    }
  }
}
