.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap;
  border: 0;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 $desktop-pd;

  @include tablet {
    padding: 0 $mobile-pd;
  }
}

.loader {
  &::after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: block;
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    box-shadow: -24px 0 $white, 24px 0 $white;
    box-sizing: border-box;
    animation: shadowPulse 0.7s linear infinite;
  }

  @keyframes shadowPulse {
    33% {
      background: $white;
      box-shadow: -24px 0 $black, 24px 0 $white;
    }
    66% {
      background: $black;
      box-shadow: -24px 0 $white, 24px 0 $white;
    }
    100% {
      background: $white;
      box-shadow: -24px 0 $white, 24px 0 $black;
    }
  }
}

@mixin loader($bgColor: $white) {
  position: relative;

  @extend .loader;

  &::before {
      background-color: $bgColor;
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      z-index: 10;
      border-radius: inherit;
  }
}

html {
  scroll-behavior: smooth;

  @include no-scrollbar;
}

body {
  @include no-scrollbar;

  background: $dark;

  *._loading {
    @include loader;
  }

  *._hidden {
    display: none;
  }

  *._disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:has(header._opened),
  &:has(.modal._opened) {
    overflow: hidden;
  }
}

.main {
  position: relative;
}

