@import "src/scss/styles";


.contact {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .button__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 18px;

    @include phone {
      gap: 12px;
    }
  }
}

.contact__icon {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;

  border-radius: 50%;

  color: $black;
  background-color: $white;

  svg {
    width: 24px;
    height: 24px;
  }
}

.contact__name {
  font-weight: 300;
  line-height: 1.21;
  text-transform: none;

  @include phone {
    font-size: 14px;
  }
}
