@import "src/scss/styles";
.means {
  z-index: 0;
  position: relative;

  background-color: $black;
}

.means__inner {
  display: flex;
  flex-direction: column;
  gap: 54px;

  padding: 100px 74px 70px;

  @include tablet {
    padding: 52px 24px;

    gap: 42px;
  }

  @include phone {
    gap: 24px;
  }
}

.means__title.h2 {
  font-weight: 400;
  text-align: center;
}

.means__content {
  @include list-reset;
  display: flex;
  gap: 42px;

  @media screen and #{$xxl} {
    flex-direction: column;
  }

  li {
    width: 100%;
  }
}

.means__card {
  display: flex;
  flex-direction: column;
  gap: 50px;

  width: 100%;

  @include phone {
    gap: 18px;
  }
}

.means__card-img-wrap {
  height: 260px;

  @include phone {
    height: 125px;
  }

  img {
    @include cover-img;
  }
}

.means__card-list {
  @include list-reset;

  display: flex;
  gap: 50px;

  @include tablet {
    gap: 32px;
  }

  @include phone {
    flex-direction: column;
  }

  li {
    &:hover {
      .means__card-line {
        background-color: $violet-2;
      }
    }
  }
}

.means__card-item {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  @include phone {
    gap: 14px;
  }
}

.means__card-top {
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 100%;

  @include phone {
    gap: 12px;
  }
}

.means__card-line {
  @include line-reset;
  width: 100%;
  max-width: 270px;
  height: 1px;

  background-color: $white;

  @include phone {
    max-width: 115px;
  }
}

.means__card-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.5;

  @include phone {
    font-size: 18px;
  }
}

.means__card-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;

  color: $white;

  @include phone {
    font-size: 14px;
  }

  b {
    font-weight: 600;
  }
}
