@import "src/scss/styles";
.textarea {
  flex-grow: 1;
  position: relative;
  width: 100%;

  &--error {
    .textarea__control {
      border: 1px solid $error;
    }
  }
}

.textarea__inner {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.textarea__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.textarea__label {
  padding: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  color: $white;

  @include tablet {
    font-size: 12px;
  }
}

.textarea__control {
  display: block;

  width: 100%;
  min-height: 180px;
  padding: 0;
  margin: 0;

  font-weight: 400;
  font-size: 18px;

  border: 0;
  outline: 0;

  border: 1px solid $white;

  color: $white;
  background-color: transparent;

  box-sizing: border-box;

  transition: $base-transition;

  &::placeholder {
    color: $grey;
  }

  &:disabled {
    opacity: 0.6;
  }
}

.textarea__error {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;

  color: $error;

  @include tablet {
    font-size: 12px;
  }
}
