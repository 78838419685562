@import "src/scss/styles";


.promo {
  display: flex;
  margin: 0 auto;
  background-color: $black;

  min-height: calc(100vh - 84px);

  @media screen and #{$xl} {
    min-height: 680px;
  }

  @include tablet {
    min-height: 760px;
  }

  @include phone {
    min-height: 530px;
  }
}

.promo__inner {
  overflow: hidden;
  position: relative;

  display: flex;

  width: 100%;

  .swiper {
    width: 100%;
  }
}

.promo__list {
  margin: 0;
  padding: 0;
}

.promo__buttons {
  position: absolute;
  width: calc(100% - 48px);
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
}

.promo__list-item {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  padding: 0 $desktop-pd;

  @include tablet {
    padding: 0 $mobile-pd;
  }
}

.promo__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 32px;

}

.promo__title {
  z-index: 2;

  font-weight: 600;
  font-size: 72px;
  line-height: 1;
  max-width: 75%;


  @include tablet {
    max-width: none;
    font-size: 52px;
  }

  @include tablet-sm {
    max-width: none;
    font-size: 42px;
  }

  @include phone {
    font-size: 24px;
  }
}

.promo__text {
  z-index: 2;

  font-weight: 400;
  font-size: 28px;
  line-height: normal;

  @include tablet {
    font-size: 22px;
  }

  @include phone {
    font-size: 16px;
  }
}

.promo__details {
  z-index: 2;
  transition: opacity $base-transition;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 14px 42px;

  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  border-radius: 10px;

  color: $black;
  background-color: $violet-2;

  @include phone {
    padding: 14px 20px;
  }

  @include hover {
    opacity: 0.8;
  }
}

.promo .promo__pagination-wrapper.swiper-pagination-bullets {
  width: max-content;
  display: flex;
  align-items: center;
  column-gap: 32px;
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
}

.promo__pagination-bullet {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: $white-op-3;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;

  @include phone {
    width: 16px;
    height: 16px;
  }
}

.promo__pagination-bullet-active {
  background-color: $white;
}

.promo__image {
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  object-fit: cover;
  inset: 0;
}
