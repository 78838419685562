@import "src/scss/styles";


.about__title {
  @extend .h1;
  @extend .container;

  font-weight: 400;
  padding-top: 120px;
  padding-bottom: 62px;

  @include tablet {
    padding-bottom: 42px;
  }

  @include phone {
    padding-top: 72px;
  }
}
