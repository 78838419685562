@import "src/scss/styles";


.logo {
  position: relative;

  /* &::after {
    content: '';
    position: absolute;
    left: calc(100% + 32px);
    top: 50%;
    transform: translateY(-50%);
    height: 31px;
    width: 1px;
    background-color: $white;
  } */

  .icon {
    width: 125px;
    height: 33px;
    @include phone {
      width: 94px;
      height: 25px;
    }
  }
}
