@import "src/scss/styles";
.privilege {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;

  height: 100%;
  padding: 48px 56px;

  background-color: #1F1C21;

  @include phone {
    padding: 32px 24px;
  }
}

.privilege__title {
  font-weight: 700;
  font-size: 24px;

  @include phone {
    font-size: 18px;
  }
}

.privilege__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.privilege__content-text {

  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;

    color: $white;

    @include phone {
      font-size: 14px;
    }
  }
}

.privilege__content-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;

  @include phone {
    font-size: 16px;
  }
}

.privilege__content-list,
.privilege__content-cards {
  @include list-reset;

  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    border-bottom: 1px solid rgba($white, 0.1);

    &:last-child {
      border-bottom: 0px;

      .privilege__content-item {
        padding-bottom: 0;
      }
    }
  }
}

.privilege__content-cards {
  gap: 48px;

  @include tablet {
    gap: 32px;
  }
}

.privilege__content-item,
.privilege__content-card {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  padding-bottom: 12px;

  .icon  {
    color: $violet-2;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;

    color: $white;

    @include phone {
      font-size: 14px;
    }
  }
}

.privilege__content-card {
  gap: 40px;

  padding-bottom: 48px;

  @include tablet {
    padding-bottom: 32px;
  }

  @include tablet-sm {
    flex-direction: column;
    gap: 24px;
  }

  .icon {
    @include size(80px);

    @include tablet-sm {
      @include size(64px);
    }
  }
}

.privilege-info {
  position: relative;
  z-index: 1;

  transition: $base-transition;

  &:hover {
    .privilege-info__content {
      visibility: visible;
      opacity: 1;
    }
  }
}

.privilege-info__btn {
  cursor: pointer;
}

.privilege-info__content {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;

  transform: translateX(-50%);
  isolation: isolate;
  visibility: hidden;

  gap: 16px;

  width: 800px;
  padding: 16px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;

  border-radius: 12px;

  color: $black;
  background-color: $white;
  box-shadow: 0 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);

  opacity: 0;

  transition: $base-transition;

  @include desktop {
    max-width: 80vw;
  }

  @include tablet {
    font-size: 14px;
    line-height: 22px;
  }

  @include tablet-sm {
    left: -15vw;
    max-width: 60vw;
  }

  @include phone {
    left: -25vw;

    max-width: 75vw;
    padding: 12px;

    font-size: 12px;
    line-height: 20px;
  }

  b {
    font-weight: 600;
    letter-spacing: 0.15px;

    color: $black;
  }

  p {
    color: $black;
  }

  p:not(:last-child) {
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 12px;
    }

    @include phone {
      margin-bottom: 8px;
    }
  }
}
