@import "src/scss/styles";
.issuance-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;

  color: rgba($violet-2, 0.5);

  cursor: pointer;
  transition: $base-transition;

  @include tablet {
    gap: 24px;
  }

  &:hover {
    color: $violet-2;

    .issuance-step__number {
      color: $violet-2;
    }

    .issuance-step__title {
      color: $violet-2;
    }

    .issuance-step__image {
      opacity: 1;
    }
  }

  &--active {
    color: $violet-2;

    .issuance-step__number {
      color: $violet-2;
    }

    .issuance-step__title {
      color: $violet-2;
    }

    .issuance-step__image {
      opacity: 1;
    }
  }
}

.issuance-step__number {
  font-weight: 600;
  font-size: 32px;
  line-height: 0.75;

  color: rgba($violet-2, 0.5);

  transition: $base-transition;
}

.issuance-step__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  text-wrap: nowrap;

  color: rgba($violet-2, 0.5);

  transition: $base-transition;
}

.issuance-step__image {

  opacity: 0.5;

  transition: $base-transition;

  img {
    @include cover-img;
  }
}
