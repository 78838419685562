@import "src/scss/styles";
.alternative {
  z-index: 0;
  display: flex;
  margin: 0 auto;
  background: $black;
  position: relative;

  @media screen and #{$xl} {
    display: block;
  }

  &--secondary {
    .alternative__item {
      background: $black;
    }
  }
}

.alternative__bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  overflow: hidden;
  background: #111111;

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 25%;
    top: 40%;
    width: 1566px;
    height: 1566px;
    background: linear-gradient(148.7deg, #421775 -16.31%, #300F58 55.84%, #22014A 122.38%);
    filter: blur(200px);
  }
}

.alternative__inner {
  position: relative;
  z-index: 2;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  @include tablet {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.alternative__title {
  max-width: 632px;

  margin: 82px 85px auto 73px;

  @media screen and #{$xl} {
    max-width: 510px;
  }

  @include tablet {
    max-width: 632px;
    margin-left: 24px;
    margin: 82px auto 82px 24px;
  }

  @include phone {
    max-width: none;
    margin: 64px 24px;
  }
}

.alternative__content {
  max-width: 55%;
  width: 100%;

  /* background-color: #282828; */

  @media screen and #{$xl} {
    max-width: 50%;
  }

  @include tablet {
    max-width: none;
  }
}

.alternative__list {
  @include list-reset;
}

.alternative__list-item {
  width: 100%;

  &:last-child {
    .alternative__item {
      border: 0;
    }
  }
}

.alternative__item {
  display: flex;

  width: 100%;
  padding: 32px 74px 32px 0;

  border-bottom: 1px solid $white;

  @include tablet {
    padding: 32px 24px;
  }
}

.alternative__item-inner {
  position: relative;

  display: flex;
  flex-direction: column;

  gap: 24px;
}

.alternative__item-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alternative__item-title.h3 {
  @include phone {
    font-size: 18px;
  }
}

.alternative__item-icon {
  color: $white;
}

.alternative__item-text {
  font-size: 14px;
  line-height: 1.5;
}

.alternative__item-source {
  word-break: break-all;
  font-size: 12px;
  line-height: 1.5;

  text-decoration: underline;
  opacity: 0.6;
}

.alternative__more {
  display: flex;

  width: 100%;
  padding: 32px 74px;

  font-weight: 500;
  font-size: 32px;
  line-height: 1.21;

  @include tablet {
    padding: 32px 24px;
  }

  @include tablet {
    font-size: 32px;
  }

  @include phone {
    font-size: 24px;
  }
}

.alternative__image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  inset: 0;

  @include tablet {
    max-height: 288px;
    object-position: 0 0;
  }
}
