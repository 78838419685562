@import "src/scss/styles";


.commodity {
  display: flex;
  justify-content: space-between;
  gap: 42px;

  @media screen and #{$xl} {
    flex-direction: column;
  }

  &--reversed {
    flex-direction: row-reverse;

    @media screen and #{$xl} {
      flex-direction: column;
    }
  }
}

.commodity__image {
  overflow: hidden;
  flex: 1;

  @media screen and #{$xl} {
    flex: auto;
    max-height: 336px;
  }
  img {
    @include cover-img;
  }
}

.commodity__content {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 42px;

  @media screen and #{$xl} {
    flex: auto;
  }
}

.commodity__top {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.commodity__title {
  font-weight: 600;
}

.commodity__description {
  p {
    font-size: 18px;

    @include phone {
      font-size: 14px;
      line-height: 1.28;
    }
  }
}

.commodity__list {
  @include list-reset;

  height: 100%;
  display: grid;
}

.commodity__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  padding: 87px 32px;

  background-color: #090909;

  @include tablet {
    padding: 39.5px 32px;
  }

  @include phone {
    padding: 32px 24px;
  }
}

.commodity__text {
  p {
    font-size: 18px;

    @include phone {
      font-size: 14px;
      line-height: 1.28;
    }
  }
  span {
    font-weight: 700;
  }
}

.commodity__source {
  color: #A8A8A8;
  p {
    font-size: 18px;

    @include phone {
      font-size: 14px;
      line-height: 1.28;
    }
  }

  a {
    text-decoration: underline;
  }
}
