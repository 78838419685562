@import "src/scss/styles";
.questions {
  z-index: 0;
  position: relative;

  background-color: $black;
}

.questions__inner {
  display: flex;
  flex-direction: column;
  gap: 46px;

  padding: 84px 74px;

  @include tablet {
    padding: 52px 24px;
  }
}

.questions__title.h2 {
  font-weight: 600;
}

.questions__main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.questions__nav-list {
  overflow-x: scroll;
  @include list-reset;
  @include no-scrollbar;
  white-space: nowrap;

  display: flex;
  gap: 24px;

  width: 100%;

  &.active {
    cursor: grabbing;
  }
}

.questions__nav-item {
  padding: 8px;
  padding-bottom: 16px;

  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  text-wrap: nowrap;

  border-bottom: 0;

  color: $white;

  cursor: pointer;
  transition: $base-transition;

  &--active {
    border-bottom: 2px solid $violet-2;

    color: $violet-2;
  }
}
