@import "src/scss/styles";
.question {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 38.5px 0;

  &--active {
    .question__top {
      .icon {
        color: $violet-2;

        transform: rotate(-45deg);
      }
    }

    .question__title {
      color: $violet-2;
    }

    .question__text {
      display: block;
    }
  }
}

.question__top {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 40px;

  cursor: pointer;

  @include tablet {
    gap: 24px;
  }

  .icon {
    color: $white;

    transform: rotate(0);
    transition: $base-transition;
  }
}

.question__title {
  font-weight: 600;
  font-size: 24px;

  color: $white;
  transition: $base-transition;
}

.question__text {
  display: none;

  font-size: 16px;
  line-height: 1.5;
  transition: $base-transition;

  @include phone {
    font-size: 14px;
  }
}
