@import "src/scss/styles";
.issuance-block {
  display: flex;
  justify-content: space-between;
  gap: 100px;

  @include desktop {
    gap: 40px;
  }

  @include tablet {
    flex-direction: column;
    justify-content: flex-start;
  }

  @include phone {
    gap: 32px;
  }
}

.issuance-block__main {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 50px;

  width: 420px;

  @include tablet {
    gap: 32px;

    width: auto;
  }

  @include tablet-sm {
    padding: 0 24px;
  }
}

.issuance-block__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.5;

  @include phone {
    font-size: 18px;
  }
}

.issuance-block__nav-list {
  @include list-reset;

  display: flex;
  flex-direction: column;

  li {
    width: 100%;

    border-bottom: 1px solid #979797;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.issuance-block__nav-item {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;

  width: 100%;
  padding: 32px;

  background-color: $black;

  cursor: pointer;
  transition: $base-transition;

  .icon {
    transform: rotate(0);
    color: $white;

    transition: $base-transition;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-blend-mode: screen;
    background: none;
  }

  &--active {
    background-color: #111112;

    &::after {
      background: radial-gradient(86.69% 100.43% at 50% 150.43%, rgba(120, 21, 237, 1) 0%, rgba(134, 51, 233, 0) 100%);
    }

    .icon {
      transform: rotate(-45deg);
      color: $violet-2;
    }
  }
}

.issuance-block__nav-item-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.issuance-block__nav-item-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
}

.issuance-block__nav-item-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.issuance-block__content {
  position: relative;
  display: flex;
  align-items: flex-end;

  min-height: 485px;
  padding: 32px;

  @include tablet {
    min-height: 440px;
  }

  @include phone {
    min-height: 530px;
    padding: 24px;
  }
}

.issuance-block__img-wrap {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  img {
    @include cover-img;
  }
}

.issuance-block__text {
  display: flex;
  flex-direction: column;
  gap: 16px;

  font-size: 16px;
  line-height: 1.5;

  color: $white;

  @include tablet {
    font-size: 14px;
  }

  p {
    font-weight: 400;
  }

  b {
    font-weight: 600;
  }
}
