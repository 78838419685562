@import "src/scss/styles";


.opportunities {
  position: relative;
  background-color: #393939;
  margin: 0 auto;

  display: flex;

  &--secondary {
    .opportunities__description {
      font-weight: 600;
    }
  }
}

.opportunities__inner {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.opportunities__top {
  display: flex;
  flex-direction: column;
  gap: 42px;

  padding: 64px 67.5px;

  @media screen and #{$xxxl} {
    padding: 64px 72px;
  }

  @include tablet {
    padding: 64px 24px;
  }
}

.opportunities__description {
  font-size: 18px;

  @include phone {
    font-size: 14px;
    line-height: 1.28;
  }
}

.opportunities__list {
  @include list-reset;

  display: flex;

  @media screen and #{$xl} {
    flex-direction: column;
  }

  @include tablet {
    padding: 0 24px;
  }
}

.opportunities__item {
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 100%;
  padding: 72px;

  border-right: 1px solid $white;

  @media screen and #{$xl} {
    border-bottom: 1px solid $white;
    border-right: 0;
  }

  @include tablet {
    padding: 72px 0;
  }

  @include phone {
    gap: 18px;
    padding: 52px 0;
  }

  &:last-child {
    border-right: 0;

    @media screen and #{$xl} {
      border-bottom: 0;
    }
  }
}

.opportunities__item-image {
  @include cover-img;

  height: auto;
  max-height: 370px;

  @include tablet {
    max-height: 330px;
  }

  @include phone {
    max-height: 340px;
  }
}

.opportunities__item-title {
  @media screen and #{$xl} {
    padding: 19.5px 0;
  }
}

.opportunities__item-text {
  font-size: 18px;
  line-height: 1.5;

  @include phone {
    font-size: 14px;
    line-height: 1.28;
  }
}
