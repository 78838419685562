@import "src/scss/styles";


.contacts {
  overflow: hidden;
  position: relative;

  display: flex;

  min-height: calc(100vh - 220px);

  background-color: $dark;

  @include tablet {
    min-height: calc(100vh - 221px);
  }
}

.contacts__inner {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: space-between;

  width: 100%;
  gap: 55px;

  @media screen and #{$xl} {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
  }
}

.contacts__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 42px;
  padding: 82px 74px;
  padding-right: 0;

  @media screen and #{$xxl} {
    padding: 42px 74px;
  }

  @media screen and #{$xl} {
    padding-right: 74px;
  }

  @include tablet {
    padding: 82px 24px;
  }

  @include phone {
    padding: 62px 24px;
    gap: 24px;
  }

  .contacts__title {
    font-weight: 400;
  }
}

.contacts__list {
  @include list-reset;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 32px;

  @include phone {
    gap: 24px;
  }
}

.contacts__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 18px;
}

.contacts__item-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.21;

  @include phone {
    font-size: 14px;
  }
}
