@import "src/scss/styles";
.resources-page {
  z-index: 0;
  
  background-color: $black;
}

.resources-page__top {
  position: relative;

  display: flex;

  padding: 85px 74px;

  @include tablet {
    padding: 62px 24px;
  }

  @include phone {
    padding: 24px;
  }
}

.resources-page__title {
  font-size: 62px;

  @include tablet {
    font-size: 52px;
  }

  @include phone {
    font-size: 32px;
  }
}

.resources-page__top-bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 232px;
    background: radial-gradient(86.69% 100.43% at 50% 150.43%, rgba(120, 21, 237, 0.8) 0%, rgba(134, 51, 233, 0) 100%);
    background-blend-mode: screen;
  }
}
