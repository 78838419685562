@import "src/scss/styles";


.vacancy-card {
  display: flex;
  flex-direction: column;
  gap: 48px;

  height: 100%;
  padding: 50px;

  background-color: #1F1C21;
}

.vacancy-card__title {
  font-weight: 700;
  font-size: 24px;
}

.vacancy-card__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.vacancy-card__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.vacancy-card__list-wrap {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.vacancy-card__list-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
}

.vacancy-card__list {
  @include list-reset;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.vacancy-card__item {
  display: flex;
  align-items: center;
  gap: 12px;

  padding-bottom: 12px;

  border-bottom: 1px solid rgba($white, 0.1);

  &:last-child {
    border-bottom: 0;
  }

  .icon {
    color: $violet-2;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
  }
}

.vacancy-card__actions {
  display: flex;
  justify-content: space-between;
  gap: 14px;

  margin-top: auto;

  @include phone {
    flex-direction: column;
  }

  .button {
    width: 100%;
    padding: 14px 0;
  }
}
