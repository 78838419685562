@import "src/scss/styles";
.insights {
  z-index: 0;
  position: relative;

  background-color: $black;
}

.insights__inner {
  z-index: 0;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 70px;

  padding: 100px 74px;

  @include tablet {
    padding: 52px 24px;
  }
}

.insights__bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 642px;
    background-blend-mode: screen;
    background: radial-gradient(76.4% 23.21% at 50% -11.15%, rgba(120, 21, 237, 1) 0%, rgba(134, 51, 233, 0) 100%);
  }
}

.insights__top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.insights__title.h2 {
  font-weight: 600;
}

.insights__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.insights__button {
  padding: 14px;
  color: $white;
  background-color: transparent;

  transition: $base-transition;

  &:hover,
  &--disabled {
    color: #B0B0B0;
  }

  .icon {
    width: 18px;
    height: 18px;
  }
}

.insights__slider {
  width: 100%;
  height: 100%;
  margin: 0;

  background-color: #090909;
}

.insights__list {
  @include list-reset;
  display: flex;

  li {
    height: auto;
  }
}

.insights .insights__pagination-wrapper.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 32px;

  margin-top: 42px;

  @include tablet {
    margin-top: 32px;
  }

  @include phone {
    margin-top: 24px;
  }

  &.swiper-pagination-lock {
    display: none;
  }
}

.insights__pagination-bullet {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #A0A0A0;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;

  @include phone {
    width: 16px;
    height: 16px;
  }
}

.insights__pagination-bullet-active {
  background-color: $violet-l;
}


