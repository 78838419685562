@import "src/scss/styles";


.map {
  position: relative;
  width: 55%;

  @media screen and #{$xl} {
    width: 100%;
    min-height: 580px;
  }

  .gm-style {}

  .gm-style-iw {
    padding: 0;
    border-radius: 0;
  }

  .gm-style-iw-d {
    padding: 0;
    overflow: hidden !important;
  }

  .gm-style-iw-chr {
    display: none;
  }

  .gm-style-iw-tc {
    &::after {
      display: none;
    }
  }
}

.map__marker {
  transform: scale(1.5);
}

.map__marker-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  width: 100%;
  height: 100%;
  padding: 32px;
  color: $black;

  p {
    font-weight: 300;
    font-size: 16px;
    font-family: 'Montserrat';
  }
}

.map__marker-info-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 18px;
}

.map__marker-info-title {
  font-weight: 700;
  font-size: 16px;
  font-family: 'Montserrat';
  color: $black;
}

.map__marker-info-icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;

  border-radius: 50%;

  color: $white;
  background-color: $black;
}
