@import "src/scss/styles";
.insight {
  display: flex;
  flex-direction: column;
  gap: 70px;

  width: 100%;
  height: 100%;
  padding: 32px;

  background-color: #1F1C21;
  cursor: pointer;

  @include tablet {
    gap: 32px;

    padding: 24px;
  }

  @include tablet-sm {
    gap: 24px;
  }
}

.insight__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 1.2;

  @include text-clamp(2);

  @include tablet {
    @include text-clamp(4);
    font-size: 20px;
  }
}

.insight__text {
  margin-top: auto;

  font-size: 16px;
  line-height: 1.5;

  @include text-clamp(3);

  @include tablet {
    @include text-clamp(9);
  }

  @include phone {
    font-size: 14px;
  }
}
