@import "src/scss/styles";


.header {
  @extend .container;
  position: sticky;
  top: 0;
  left: 0;

  background: $dark;
  backdrop-filter: blur(2px);
  z-index: 5;

  &._opened {
    .menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;

  @include tablet {
    padding: 40px 0;
  }

  @include phone {
    padding: 24px 0;
  }
}

.header__nav {
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  padding-left: 20px;
  flex-grow: 1;

  @include tablet-sm {
    display: none;
  }
}

.header__list {
  @include list-reset;

  display: flex;
  column-gap: 42px;
  margin: 0 auto;

  @include desktop {
    column-gap: 15px;
  }

  @include tablet {
    column-gap: 10px;
  }

  li {
    position: relative;
    display: flex;
    align-items: center;

    @include hover {
      ul {
        opacity: 1;
        visibility: visible
      }

      &::after {
        transform: rotate(-180deg);
      }
    }

    &:has(ul) {
      &::after {
        position: static;
        content: '';
        margin-left: 12px;

        --s: 2px;
        width: 10px;
        aspect-ratio: 4/2.5;
        clip-path: polygon(0 0,0 var(--s),50% 100%,100% var(--s),100% 0,50% calc(100% - var(--s)));
        background: $white;
        transition: transform $base-transition;

        @include tablet {
          margin-left: 8px;
        }
      }
    }
  }
}

.header__sublist {
  @include list-reset;

  position: absolute;
  top: calc(100% + 32px);
  left: 0;
  background-color: $dark;
  padding: 42px 24px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  z-index: 3;
  width: max-content;
  transition: opacity $base-transition, visibility $base-transition;
  border: 1px solid #FFFFFF80;

  opacity: 0;
  visibility: hidden;
  min-width: 415px;
}

.header__sublist-item {
  transition: $base-transition;

  &.active {
    color: #DDC0FF;
  }

  @include hover {
    color: #DDC0FF;
  }
}

.header__list-item {
  font-size: 14px;
  text-transform: uppercase;

  transition: $base-transition;

  @include desktop {
    font-size: 12px;
  }

  @include tablet {
    font-size: 10px;
  }

  &.active {
    color: #DDC0FF;
  }

  @include hover {
    color: #DDC0FF;
  }
}

.header__actions {
  @include list-reset;

  display: flex;
  align-items: center;
  column-gap: 12px;
}

.header__action-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 42px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;

  @include desktop {
    padding: 11px 30px;

    font-size: 12px;
  }

  @include tablet {
    padding: 10px 15px;

    font-size: 10px;
  }

  @include phone {
    padding: 14px 20px;
  }
}

.header__menu {
  @include button-reset;

  display: none;

  span {
    position: relative;
    display: inline-block;
    width: 30px;
    border-bottom: 2px solid $violet-2;
    border-radius: 2px;
    transition: border-color $base-transition;

    &:before,
    &::after {
      border-radius: inherit;
      border-bottom: 2px solid $violet-2;
      content: '';
      display: block;
      width: 100%;
      height: 0;
      position: absolute;
      left: 0;
      transition: all $base-transition;
      transform-origin: center;
    }

    &:before {
      top: -7px;
    }

    &:after {
      bottom: -9px;
    }
  }

  &--opened {
    span {
      border-color: transparent;

      &:before {
        transform: translateY(7px) rotate(45deg);
      }

      &:after {
        transform: translateY(-7px) rotate(-45deg);
      }
    }
  }

  @include tablet-sm {
    display: block;
  }
}

