@import "src/scss/styles";
.news-link {
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px;

  @include phone {
    padding: 24px 0;
  }
}

.news-link__top {
  @include button-reset;
  
  cursor: auto;

  &--active {
    cursor: pointer;
  } 
}

.news-link__top,
.news-link__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  @include tablet {
    gap: 24px;
  }
}

.news-link__title {
  font-weight: 500;
  font-size: 32px;
  line-height: 1.2;
  text-align: left;

  @include tablet {
    font-size: 28px;
  }

  @include phone {
    font-size: 20px;
  }
}

.news-link__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.news-link__date,
.news-link__company {
  font-size: 15px;
  color: rgba($white, 0.7);
}

.news-more__title {
  font-size: 24px;
}
