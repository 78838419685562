@import "src/scss/styles";


.mining-info {
  display: flex;
  margin: 0 auto;
  background-color: #111111;
}

.mining-info__inner {
  overflow: hidden;
  padding: 82px 74px 32px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  @include tablet {
    padding: 64px 24px 32px;
  }

  @include phone {
    gap: 24px;
    padding: 62px 24px 32px;
  }
}

.mining-info__list {
  @include list-reset;

  display: flex;
  flex-direction: column;
  gap: 96px;

  @include tablet {
    gap: 48px;
  }

  @include phone {
    gap: 24px;
  }
}

.mining-info__item {
  display: flex;
  justify-content: space-between;
  gap: 64px;

  padding-bottom: 96px;

  border-bottom: 1px solid rgba($white, 0.2);

  @media screen and #{$xl} {
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
  }

  @include tablet {
    padding-bottom: 48px;
  }

  @include phone {
    gap: 24px;
    padding-bottom: 24px;
  }
}

.mining-info__item-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  height: 100%;

  @media screen and #{$xl} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  @include phone {
    gap: 16px;
  }

  /* .icon {
    width: 128px;
    height: 128px;

    margin-top: auto;

    color: $violet-2;

    @include tablet {
      width: 96px;
      height: 96px;

      margin-top: 0;
    }

    @include phone {
      width: 48px;
      height: 48px;
    }
  } */
}

.mining-info__item-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 1.21;

  @media screen and #{$xl} {
    max-width: 680px;
  }

  @include tablet-sm {
    font-size: 28px;
  }

  @include phone {
    font-size: 24px;
  }
}

.mining-info__image {
  flex-shrink: 0;
  width: 128px;
  height: 128px;

  margin-top: auto;

  color: $violet-2;

  @include tablet {
    width: 96px;
    height: 96px;

    margin-top: 0;
  }

  @include phone {
    width: 48px;
    height: 48px;
  }
  
  img {
    @include cover-img;
  }
}

.mining-info__item-description {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 45%;

  @media screen and #{$xxl} {
    width: 60%;
  }

  @media screen and #{$xl} {
    width: 100%;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;

    @include tablet {
      font-size: 16px;
    }

    @include phone {
      font-size: 14px;
    }
  }
}
