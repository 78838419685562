html,
body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: $font-family;
  color: $white;
}


@mixin h1 {
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;

  @include tablet {
    font-size: 62px;
  }

  @include phone {
    font-size: 24px;
    line-height: 32px;
  }
}

.h1 {
  @include h1;
}

@mixin h2 {
  font-size: 48px;
  font-weight: 500;
  line-height: 130%;

  @include tablet {
    font-size: 42px;
  }

  @include phone {
    font-size: 24px;
  }
}

.h2 {
  @include h2;
}

@mixin h3 {
  font-size: 28px;
  font-weight: 500;
  line-height: normal;

  @include tablet {
    font-size: 32px;
  }

  @include phone {
    font-size: 24px;
  }
}

.h3 {
  @include h3;
}

@mixin h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;

  @include tablet {
    font-size: 32px;
  }

  @include phone {
    font-size: 18px;
  }
}

.h4 {
  @include h4;
}


@mixin text-2 {
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;

  @include phone {
    font-size: 14px;
    line-height: 17px;
  }
}

.text-2 {
  @include text-2;
}

