@import "src/scss/styles";


.vacancies {
  overflow: hidden;
  position: relative;

  display: flex;

  margin: 0 auto;

  background-color: #111111;
}

.vacancies__inner {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 72px;

  width: 100%;
  padding: 84px 74px;

  @include tablet {
    gap: 52px;
    padding: 64px 24px;
  }

  @include phone {
    gap: 42px;
  }

  &--empty {
    gap: 20px;
  }
}

.vacancies__top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.vacancies__title.h2 {
  font-weight: 600;
}

.vacancies__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.vacancies__button {
  padding: 14px;
  color: $white;
  background-color: transparent;

  transition: $base-transition;

  &:hover,
  &--disabled {
    color: #B0B0B0;
  }

  .icon {
    width: 18px;
    height: 18px;
  }
}

.vacancies__bottom {
  display: flex;
  flex-direction: column;
}

.vacancies__text {
  font-weight: 400;
  font-size: 18px;
}

.vacancies__slider {

  width: 100%;
  height: 100%;
  margin: 0;
}

.vacancies__list {
  @include list-reset;

  display: flex;
  width: 100%;
}

.vacancies .vacancies__pagination-wrapper.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 32px;

  margin-top: 50px;

  @include tablet {
    margin-top: 32px;
  }

  @include phone {
    margin-top: 24px;
  }

  &.swiper-pagination-lock {
    display: none;
  }
}

.vacancies__pagination-bullet {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #A0A0A0;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;

  @include phone {
    width: 16px;
    height: 16px;
  }
}

.vacancies__pagination-bullet-active {
  background-color: $violet-l;
}
