@import "src/scss/styles";


.page-links {
  background-color: #111111;
}

.page-links__inner {
  display: flex;
  justify-content: space-between;
  gap: 32px;

  padding: 32px 74px 96px;

  @include tablet {
    padding: 24px 24px 64px;
  }

  @include phone {
    gap: 16px;
    padding: 24px 24px 32px;
  }
}

.page-links__button {
  width: 100%;
  padding: 96px 40px 32px;

  border-radius: 0px;

  color: $white;
  border-color: rgba($white, 0.2);

  @media screen and #{$xxl} {
    padding-top: 64px;
  }

  @include tablet {
    padding: 10px 16px;
  }

  @include phone  {
    padding: 6px 8px;
  }

  &--next {
    .button__content {
      flex-direction: row-reverse;
    }
  }

  .button__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    text-align: right;
  }

  .icon {
    @include phone {
      width: 16px;
      height: 16px;
    }
  }

  span {
    font-weight: 500;
    font-size: 24px;

    text-transform: none;

    @media screen and #{$xxl} {
      font-size: 18px;
    }

    @include tablet {
      font-size: 14px;
    }

    @include phone {
      font-size: 12px;
    }
  }
}
