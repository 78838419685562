@import "src/scss/styles";


.banner-link {
  position: relative;

  display: flex;

  margin: 0 auto;

  background-color: $black;

  min-height: calc(100vh - 84px);

  @media screen and #{$xl} {
    min-height: calc(100vh - 97px);
  }

  @include tablet {
    min-height: calc(100vh - 113px);
  }

  @include phone {
    min-height: 301px;
  }

  &--secondary {
    min-height: auto;

    background-color: #1F1C21;
  }
}

.banner-link__inner {
  z-index: 2;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 42px;

  width: 100%;
  padding: 84px 74px;

  @include tablet {
    padding: 64px 24px;
  }

  @include phone {
    gap: 24px;
  }
}

.banner-link__top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.banner-link__title {
  @media screen and #{$xl} {
    max-width: 768px;
  }

  @include tablet {
    max-width: none;
  }
}

.banner-link__image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  img {
    @include cover-img;
  }
}
