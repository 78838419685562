@import "src/scss/styles";


.news {
  overflow: hidden;
  position: relative;

  display: flex;

  margin: 0 auto;

  background-color: #111111;
}

.news__inner {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 72px;

  width: 100%;
  padding: 82px 0;

  @include tablet {
    gap: 52px;
    padding: 64px 0;
  }

  @include phone {
    gap: 42px;
  }
}

.news__top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 0 67px;

  @media screen and #{$xl} {
    padding: 0 74px;
  }

  @include tablet {
    padding: 0 24px;
  }
}

.news__title.h2 {
  font-weight: 600;
}

.news__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.news__button {
  padding: 14px;
  color: $white;
  background-color: transparent;

  transition: $base-transition;

  &:hover,
  &--disabled {
    color: #B0B0B0;
  }

  .icon {
    width: 18px;
    height: 18px;
  }
}

.news__bottom {
  display: flex;
  flex-direction: column;
}

.news__slider {
  overflow: visible;

  width: 100%;
  height: 100%;
  margin: 0;

  background-color: #090909;
}

.news__list {
  @include list-reset;

  display: flex;
  width: 100%;
}

.news__item {
  border-right: 1px solid $white;


  &:last-child {
    border-right: 0;
  }

  a {
    display: flex;
    flex-direction: column;
    gap: 32px;

    width: 100%;
    height: 100%;
    padding: 72px;

    @include tablet-sm {
      padding: 18px 24px;
    }
  }
}

.news__item-images {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.news__item-logo,
.news__item-image {
  img {
    @include cover-img;
  }
}

.news__item-image {
  width: 100%;
}

.news__item-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 1.2;
  display: -webkit-box;         /* Создаем флекс-контейнер */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  -webkit-line-clamp: 2;       /* Ограничеваем 2 строки */
  overflow: hidden;             /* Скрываем переполняющий текст */
  text-overflow: ellipsis;      /* Добавляем троеточие */
}

.news__item-text {
  display: -webkit-box;         /* Создаем флекс-контейнер */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  -webkit-line-clamp: 2;       /* Ограничеваем 2 строки */
  overflow: hidden;             /* Скрываем переполняющий текст */
  text-overflow: ellipsis;      /* Добавляем троеточие */
  margin-top: auto;
}

.news__item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.news__item-date,
.news__item-source {
  font-size: 15px;
  opacity: 0.7;
}


.news .news__pagination-wrapper.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 32px;

  margin-top: 42px;

  @include tablet {
    margin-top: 32px;
  }

  @include phone {
    margin-top: 24px;
  }

  &.swiper-pagination-lock {
    display: none;
  }
}

.news__pagination-bullet {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #A0A0A0;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;

  @include phone {
    width: 16px;
    height: 16px;
  }
}

.news__pagination-bullet-active {
  background-color: $violet-l;
}
