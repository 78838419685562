@import "src/scss/styles";

.privileges {
  position: relative;
  z-index: 0;
}

.privileges__bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  overflow: hidden;
  background: #111111;

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: -1450px;
    left: 50%;
    transform: translateX(-50%);
    width: 1566px;
    height: 1566px;
    background: linear-gradient(148.7deg, #421775 -16.31%, #300F58 55.84%, #22014A 122.38%);
    filter: blur(200px);
  }
}

.privileges__inner {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 96px;

  width: 100%;
  height: 100%;
  padding: 96px 194px;

  @media screen and #{$xxxl} {
    padding: 96px 40px;
  }

  @include tablet {
    gap: 64px;

    padding: 80px 24px;
  }

  @include phone {
    gap: 48px;

    padding: 64px 24px;
  }
}

.privileges__title {
  font-weight: 500;
  font-size: 52px;
  line-height: 1.2;
  text-align: center;

  @include tablet {
    font-size: 42px;
  }

  @include phone {
    font-size: 24px;
  }
}

.privileges__list {
  @include list-reset;

  display: flex;
  gap: 32px;

  li {
    width: 100%;
  }

  @media screen and #{$xl} {
    flex-direction: column;
  }

  @include phone {
    gap: 24px;
  }
}
