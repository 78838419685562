@import "src/scss/styles";
.m-staff {
    overflow-y: auto;
    @include scrollbar;
  
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 52px;
  
    @include tablet {
      gap: 36px;
      grid-template-columns: 1fr;
    }
  }
  
  .m-staff__image {
    overflow: hidden;
    display: flex;
    
    opacity: 0.8;
    border-radius: 10px;
  
    height: 480px;
  
    @media screen and #{$xxl} {
      max-width: 265px;
      height: 380px;
    }
  
    @include desktop {
      max-width: 205px;
      height: 295px;
    }
  
    @include tablet {
      height: 300px;
    }

    img {
      @include cover-img;
    }
  }
  
  .m-staff__position {
    opacity: 0.5;
  }
  
  .m-staff__info {
    @extend .text-2;
    /* overflow: hidden; */
  
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  
  .m-staff__description {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    max-height: 80%;
    padding-right: 10px;
    margin-right: -10px;
  
    font-size: 16px;
  
    @include tablet {
      max-height: calc(90vh - 550px);
    }

    b {
      font-weight: 700;
    }
  }