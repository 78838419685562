@import "src/scss/styles";


.next {
  position: relative;
  z-index: 0;
}

.next__bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  overflow: hidden;
  background: #111111;

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    bottom: 87px;
    width: 1566px;
    height: 1566px;
    background: linear-gradient(148.7deg, #421775 -16.31%, #300F58 55.84%, #22014A 122.38%);
    filter: blur(200px);
  }
}

.next__inner {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 580px;
  padding: 52px 67px;

  @include tablet {
    min-height: 450px;
    padding: 82px 24px;
  }

  @include phone {
    min-height: 400px;
    padding: 52px 24px;
  }
}

.next__title {
  max-width: 1220px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 63px;
  text-align: center;
  color: $white;

  @include tablet {

  }

  @include phone {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }
}

.next__text {
  max-width: 762px;
  margin-bottom: 42px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: $white;

  @include tablet {
    font-size: 16px;
    line-height: 20px;
  }

  @include phone {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.next__bottom {
  display: flex;
  align-items: center;
  gap: 24px;
}

.next-info {
  position: relative;
  z-index: 1;

  transition: $base-transition;

  &:hover {
    .next-info__content {
      visibility: visible;
      opacity: 1;
    }
  }
}

.next-info__content {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  visibility: hidden;
  width: 908px;
  padding: 16px;
  gap: 16px;
  isolation: isolate;
  transform: translateX(-50%);
  opacity: 0;
  background: $white;
  box-shadow: 0 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: $grey;
  transition: $base-transition;

  @media (max-width: 1280px){
    left: auto;
    right: -20vw;
    max-width: 80vw;
    transform: translateX(0);
  }

  @include tablet {
    font-size: 14px;
    line-height: 22px;
  }

  @include phone {
    right: -10px;
    max-width: 310px;
    padding: 12px;
    font-size: 12px;
    line-height: 20px;
  }

  b {
    font-weight: 600;
    letter-spacing: 0.15px;
    color: $black;
  }

  p:not(:last-child) {
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 12px;
    }

    @include phone {
      margin-bottom: 8px;
    }
  }
}

.next-info__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 48px;
  border: 1px solid $violet-2;
  border-radius: 10px;
  cursor: pointer;
  color: $violet-2;
}
