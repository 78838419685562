@import "src/scss/styles";
.feedback {
  display: flex;
  justify-content: center;

  background-color: #111111;
}

.feedback__inner {
  display: flex;
  flex-direction: column;
  gap: 120px;

  max-width: 1048px;
  padding: 84px 74px;

  @include tablet {
    gap: 80px;
    padding: 64px 24px;
  }

  @include phone {
    gap: 40px;
  }
}

.feedback__top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  @include phone {
    gap: 32px;
  }
}

.feedback__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;

  @include tablet {
    font-size: 16px;
  }

  a {
    font-weight: 600;
  }
}

.feedback__form {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.feedback__form-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;

  a {
    text-decoration: underline;
  }
}

.feedback__form-submit {
  align-self: flex-start;

  @include tablet-sm {
    align-self: stretch;
  }
}

.popover__text {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

.popover__link {
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
}
