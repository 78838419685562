@import "src/scss/styles";


.modal {
  position: relative;
  position: fixed;
  inset: 0;
  z-index: 10;
  transition: opacity $base-transition, visibility $base-transition;
  opacity: 0;
  visibility: hidden;
  min-width: 100vw;
  min-height: 100vh;

  &._opened {
    opacity: 1;
    visibility: visible;
  }
}

.modal__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: 84px 52px;
  min-width: 80vw;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: $dark;

  @include desktop {
    padding: 52px;
  }

  @include tablet {
    width: 100%;
    min-width: none;
    max-width: 768px;
    max-height: 90vh;
  }

  @include phone {
    padding: 48px 24px;
  }
}

.modal__close {
  @include button-reset;

  position: absolute;
  z-index: 10;

  right: 52px;
  top: 52px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: $white;

  @include tablet {
    right: 24px;
    top: 24px;
  }

  @include phone {
    right: 16px;
    top: 16px;
  }
}

.modal__overlay {
  background-color: rgba(#454545, 0.64);
  position: fixed;
  z-index: -1;
  height: 100vh;
  inset: 0;
}
