@import "src/scss/styles";
.news-links {
  z-index: 0;
  position: relative;

  background-color: $black;
}

.news-links__inner {
  display: flex;
  flex-direction: column;
  gap: 32px;

  padding: 70px 74px;

  @include tablet {
    padding: 52px 24px;
  }
}

.news-links__top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.news-links__title.h2 {
  font-weight: 600;
}

.news-links__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.news-links__button {
  padding: 14px;
  color: $white;
  background-color: transparent;

  transition: $base-transition;

  &:hover,
  &--disabled {
    color: #B0B0B0;
  }

  .icon {
    width: 18px;
    height: 18px;
  }
}

.news-links__slider {
  width: 100%;
  height: 100%;
  margin: 0;

  background-color: #090909;
}

.news-links__slider-list {
  @include list-reset;
}

.news-links__list {
  @include list-reset;
  display: flex;
  flex-direction: column;

  li {
    border-bottom: 1px solid $white;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.news-links .news-links__pagination-wrapper.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 32px;

  margin-top: 42px;

  @include tablet {
    margin-top: 32px;
  }

  @include phone {
    margin-top: 24px;
  }

  &.swiper-pagination-lock {
    display: none;
  }
}

.news-links__pagination-bullet {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #A0A0A0;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;

  @include phone {
    width: 16px;
    height: 16px;
  }
}

.news-links__pagination-bullet-active {
  background-color: $violet-l;
}
