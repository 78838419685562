@import "src/scss/styles";
.development {
  position: relative;
  display: flex;

  min-height: calc(100vh - 84px);
  margin: 0 auto;

  background: #111111;

  @media screen and #{$xl} {
    min-height: calc(100vh - 97px);
  }

  @include tablet {
    min-height: calc(100vh - 113px);
  }

  @include phone {
    min-height: calc(100vh - 73px);
  }
}

.development__inner {
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  gap: 42px;
  padding: 0 89px;
}
