@import "src/scss/styles";


.story {
  @extend .container;

  background-color: $black;
  position: relative;
}

.story__inner {
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  min-height: calc(100vh - 84px);
  padding: 82px 0;

  @media screen and #{$xl} {
    min-height: calc(100vh - 97px);
  }

  @include tablet {
    min-height: calc(100vh - 113px);
  }

  @include phone {
    min-height: calc(100vh - 73px);

    padding: 62px 0;
  }
}

.story__title {
  position: relative;
  z-index: 1;
}

.story__description {
  position: relative;
  z-index: 1;
  max-width: 80%;

  @extend .text-2;

  @include tablet {
    max-width: none;
  }
}

.story__bottom {
  @include list-reset;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  z-index: 1;
  background-color: #080808DE;
  border-radius: 10px;
  margin-top: auto;

  @include tablet {
    grid-template-columns: 1fr;
  }
}

.story__bottom-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 52px 72px;

  &:not(:last-child) {
    border-right: 1px solid $white;
  }

  @include tablet {
    &:not(:last-child) {
      border-right: none;
      border-bottom: 1px solid $white;
    }
  }

  @include phone {
    padding: 42px 24px;
  }
}

.story__bottom-title {

  margin-bottom: 45px;

  @include phone {
    margin-bottom: 90px;
  }
}

.story__bottom-description {
  @extend .text-2;
}


.story__image {
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  inset: 0;
}
