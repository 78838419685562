@import "src/scss/styles";


.menu {
  background: $dark;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  transition: opacity $base-transition, visibility $base-transition;
  opacity: 0;
  visibility: hidden;

  &._active {
    opacity: 1;
    visibility: visible;
  }

  @media screen and (min-width: 1281px) {
    display: none;
  }
}

.menu__inner {
  display: flex;
  flex-direction: column;
  padding: 42px 24px;
  height: 100dvh;
  min-width: 460px;

  @include phone {
    padding: 22px 20px;
    min-width: 75vw;
  }
}

.menu__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 85px;
}

.menu__close {
  @include button-reset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  span {
    position: relative;
    display: inline-block;
    width: 20px;
    border-radius: 2px;
    border-color: transparent;
    transition: border-color $base-transition;

    &:before,
    &::after {
      border-radius: inherit;
      border-bottom: 3px solid $white;
      content: '';
      display: block;
      width: 100%;
      height: 0;
      position: absolute;
      left: 0;
      transition: all $base-transition;
      transform-origin: center;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.menu__title {
  @extend .h3;

  text-transform: uppercase;
}

.menu__nav {

  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu__list {
  @include list-reset;
  @include scrollbar;

  display: flex;
  flex-direction: column;
  row-gap: 42px;
  flex-grow: 1;
  margin-bottom: 40px;
  overflow-y: auto;
  max-height: 60vh;
  padding-right: 10px;
  margin-right: -10px;


  li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 24px;
    transition: opacity $base-transition;
    position: relative;

    @include hover {
      opacity: 0.8;
    }

    &:has(ul) {
      &::after {
        position: absolute;
        content: '';
        right: 12px;
        top: 10px;
        transform: translateY(-50%);

        --s: 2px;
        width: 10px;
        aspect-ratio: 4/2.5;
        clip-path: polygon(0 0,0 var(--s),50% 100%,100% var(--s),100% 0,50% calc(100% - var(--s)));
        background: $white;
        transition: transform $base-transition;
      }
    }

    &._active {
      &::after {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
  }
}

.menu__sublist {
  @include list-reset;

  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: max-content;
  transition: opacity $base-transition, visibility $base-transition, height $base-transition;
  height: 0;
  overflow: hidden;
}

.menu__actions {
  @include list-reset;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 12px;
  margin-top: auto;
}

.menu__action-item {
  width: 100%;
}

.menu__overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: -1;
  height: 100vh;
  inset: 0;
}
