@import "src/scss/styles";


.popover {
  position: relative;
  position: fixed;
  inset: 0;
  z-index: 10;
  transition: opacity $base-transition, visibility $base-transition;
  opacity: 0;
  visibility: hidden;
  min-width: 100vw;
  min-height: 100vh;

  &._opened {
    opacity: 1;
    visibility: visible;
  }
}

.popover__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 52px;
  width: 1000px;
  transform: translate(-50%, -50%);
  background: #1F1C21;
  min-height: 217px;

  @include desktop {
    padding: 52px;
  }

  @include tablet {
    width: 100%;
    min-width: none;
    max-width: 768px;
    max-height: 90vh;
  }

  @include phone {
    padding: 48px 24px;
  }
}

.popover__close {
  @include button-reset;

  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  color: $white;

  @include tablet {
    right: 24px;
    top: 24px;
  }

  @include phone {
    right: 16px;
    top: 16px;
  }
}

.popover__overlay {
  background-color: rgba(#454545, 0.64);
  position: fixed;
  z-index: -1;
  height: 100vh;
  inset: 0;
}
