@import "src/scss/styles";


.back-link {
  position: absolute;
  top: 64px;
  left: 74px;

  display: flex;

  @media screen and #{$xxl} {
    top: 24px;
  }

  @include tablet {
    top: 16px;
    left: 24px;
  }
}

.back-link__button {
  padding: 12px 24px;

  color: #7E7E7E;
  border-color: #7E7E7E;

  @include tablet {
    padding: 10px 16px;
  }

  .button__content {
    display: flex;
    gap: 10px;
  }

  span {
    font-weight: 500;
    font-size: 16px;

    text-transform: none;

    @include tablet {
      font-size: 14px;
    }
  }
}
