@import "src/scss/styles";


.footer {
  @extend .container;

  background: #111111;
  backdrop-filter: blur(2px);
}

.footer__inner {
  display: flex;
  flex-direction: column;
}

.footer__content {
  display: flex;
  justify-content: space-between;

  padding: 52px 0;
  gap: 100px;

  @media screen and #{$xl} {
    flex-direction: column;

    gap: 67px;
  }
}

.footer__main {
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 32px;
}

.footer__logo {
  &::after {
    display: none;
  }
}

.footer__nav {

  display: flex;
  justify-content: flex-end;

  @media screen and #{$xl} {
    justify-content: flex-start;
  }
}

.footer__list {
  @include list-reset;

  display: grid;
  grid-template: 1fr / repeat(6, 1fr);
  gap: 24px;
  margin: 0;

  @media screen and #{$xxl} {
    grid-template: 1fr / repeat(5, 1fr);
  }

  @media screen and #{$xl} {
    grid-template: 1fr / 1fr;
  }

  li {
    display: flex;
    flex-direction: column;

    margin: 0;
    gap: 24px;
  }
}

.footer__list-item {
  font-weight: 500;
  line-height: 1.21;

  text-transform: uppercase;
}

.footer__sublist {
  @include list-reset;

  display: flex;
  flex-direction: column;

  gap: 12px;
}

.footer__sublist-item {
  font-weight: 300;
  line-height: 1.21;
}

.footer__bottom {
  display: flex;

  width: 100%;
  padding: 30px 0;

  border-top: 1px solid $white;
}

.footer__copyright {
  font-weight: 400;
  line-height: 1.21;

  color: $white;
}
