@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../../public/fonts/montserrat-300.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../public/fonts/montserrat-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../../public/fonts/montserrat-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../../public/fonts/montserrat-600.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../public/fonts/montserrat-700.woff2') format('woff2');
}


@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../../public/fonts/roboto-300.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../public/fonts/roboto-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../../public/fonts/roboto-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../public/fonts/roboto-700.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('../../public/fonts/inter-300.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../../public/fonts/inter-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../../public/fonts/inter-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../../public/fonts/inter-600.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../../public/fonts/inter-700.woff2') format('woff2');
}

@font-face {
  font-family: 'Geometria';
  font-display: swap;
  src: url('../../public/fonts/geometria-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  font-display: swap;
  src: url('../../public/fonts/geometria-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  font-display: swap;
  src: url('../../public/fonts/geometria-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  font-display: swap;
  src: url('../../public/fonts/geometria-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
