@import "src/scss/styles";

.advantages {
  overflow: hidden;
  position: relative;

  display: flex;

  min-height: calc(100vh - 84px);
  margin: 0 auto;

  background-color: $black;

  @media screen and #{$xl} {
    min-height: calc(100vh - 97px);
  }

  @include tablet {
    min-height: calc(100vh - 113px);
  }

  @include phone {
    min-height: calc(100vh - 73px);
  }

  &--secondary {
    .advantages__title {
      max-width: 965px;
    }

    .advantages__text {
      font-weight: 500;
      max-width: 559px;

      @include tablet {
        max-width: 340px;
      }
    }
  }
}

.advantages__inner {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  padding: 82px 67px;
  gap: 42px;

  @include desktop {
    padding: 82px 74px;
  }

  @include tablet {
    padding: 64px 24px;
  }

  @include phone {
    padding: 62px 24px;
  }
}

.advantages__title {
  max-width: 965px;

  @include phone {
    font-weight: 600;
  }
}

.advantages__text {
  display: flex;
  flex-direction: column;
  gap: 42px;

  margin-bottom: 120px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  @include tablet {
    margin-bottom: 80px;

    font-size: 16px;
    line-height: 20px;
  }

  @include phone {
    gap: 24px;

    font-size: 14px;
    line-height: 18px;
  }
}

.advantages__content {
  width: 100%;
  margin-top: auto;

  @media screen and #{$xl} {
    overflow: visible;
  }
}

.advantages__list {
  @include list-reset;

  li {
    display: flex;
    height: auto;
    min-height: 223px;
  }
}

.advantages__item {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding-bottom: 42px;
  gap: 32px;

  border-bottom: 5px solid $white;
  transition: $base-transition;

  @include phone {
    gap: 24px;
  }

  &:hover {
    border-bottom: 5px solid $violet-2;
  }
}

.advantages__item-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;

  @include phone {
    font-size: 16px;
    line-height: 1.21;
  }
}

.advantages__item-text {}

.advantages__image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  inset: 0;

  @media screen and #{$xl} {
    object-position: 0 0;
  }

  @include tablet {
    object-position: center;
  }
}
