@import "src/scss/styles";


.breadcrumbs {
  @extend .container;

  background-color: $black;
}

.breadcrumbs__list {
  @include list-reset;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  color: $white;
  column-gap: 12px;
  row-gap: 10px;

  font-weight: 300;
  padding: 24px 0;

  @include phone {
    padding: 32px 0;
  }
}

.breadcrumbs__link {
  position: relative;
  display: inline-block;

  color: $white;
  transition: $base-transition;

  &:not(.breadcrumbs__text)::after {
    content: '/';
    margin-left: 12px;
    color: $white;
    cursor: default;
  }

  &::first-letter {
    text-transform: uppercase;
  }

  @include hover {
    color: #DDC0FF;

    &::after {
      color: $white;
    }
  }
}

.breadcrumbs__text {
  @extend .breadcrumbs__link;

  opacity: 0.8;
  pointer-events: none;
}
