@import "src/scss/styles";
.commodities-page__title {
  font-weight: 500;
  font-size: 62px;
  line-height: 1.2;

  @include tablet {
    font-size: 52px;
  }

  @include phone {
    font-size: 24px;
  }
}
