@import "src/scss/styles";



.careers-main {
  z-index: 0;
  position: relative;

  display: flex;

  margin: 0 auto;

  background: linear-gradient(0deg, #1C191E, #1C191E),
  radial-gradient(51.77% 112.35% at 101.77% 141.24%, rgba(134, 51, 233, 0.36) 0%, rgba(134, 51, 233, 0) 92.35%);
  ;
}

.careers-main__bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    right: 50%;
    bottom: 300px;
    width: 1566px;
    height: 1566px;
    background: linear-gradient(148.7deg, #421775 -16.31%, #300F58 55.84%, #22014A 122.38%);
    filter: blur(200px);
  }
}

.careers-main__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 60px;

  width: 100%;
  padding: 126px 74px;

  @include tablet {
    flex-direction: column;
    justify-content: flex-start;

    padding: 94px 24px;
  }

  @include phone {
    padding: 70px 24px;
  }
}

.careers-main__main {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.careers-main__title.h1 {
  font-weight: 400;
}

.careers-main__description {
  max-width: 484px;

  font-weight: 400;
  font-size: 24px;

  @include desktop {
    max-width: 374px;

    font-size: 18px;
  }

  @include phone {
    max-width: none;
  }
}

.careers-main__list-wrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;

  max-width: 720px;

  @include desktop {
    max-width: 540px;
  }

  @include tablet {
    max-width: none;
    width: 100%;
  }

  @include phone {
    gap: 15px;
  }
}

.careers-main__list-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
}

.careers-main__list {
  @include list-reset;

  display: flex;
  flex-direction: column;
  gap: 20px;

  @include phone {
    gap: 15px;
  }

  li {
    padding-bottom: 20px;

    border-bottom: 1px solid #595959;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.careers-main__item {
  padding-bottom: 20px;
}
