@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

@mixin line-reset {
  margin: 0;
  padding: 0;
  border: 0;
}

@mixin cover-img {
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

@mixin contain-img {
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

@mixin hover {
  @media (min-width: 1025px) {
    &:hover {
      @content;
    }
  }
}

@mixin hoverParent($parentSelector) {
  @media (hover: hover) and (min-width: 1025px) {
      #{$parentSelector}:hover & {
          @content;
      }
  }
}

@mixin phone {
  @media screen and #{$sm} {
    @content;
  }
}

@mixin tablet {
  @media screen and #{$lg} {
    @content;
  }
}

@mixin tablet-sm {
  @media screen and #{$md} {
    @content;
  }
}

@mixin tabletonly {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media screen and #{$xl} {
    @content;
  }
}

@mixin desktoponly {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin text-clamp($lines: 3) {
  -webkit-line-clamp: $lines;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin scrollbar(
  $size: 3px,
  $foreground-color: grey,
  $background-color: $white,
  $border-radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $foreground-color;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-track {
    background-color: $background-color;
    border-radius: $border-radius;
  }
}

@mixin no-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

