@import "src/scss/styles";


.button {
  @include button-reset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 42px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  color: $white;
  background-color: $black;
  transition: $base-transition;

  @include tablet {

  }

  @include phone {
    padding: 14px 20px;
  }

  &--primary {
    color: $black;
    background-color: $violet-2;

    @include hover {
      background: $white;
    }
  }

  &--secondary {
    color: $white;
    background-color: transparent;
    border: 1px solid #DDC0FF;

    @include hover {
      color: $black;
      background-color: $violet-2;
    }
  }

  &--white {
    color: $black;
    background-color: $white;
  }

  &--bordered {
    border: 1px solid $violet-2;

    color: $black;
    background-color: $violet-2;
  }

  &--circle {
    border-radius: 50%;
  }

  &--transparent {
    color: $white;
    background-color: transparent;
  }

  &--reversed {
    flex-direction: row-reverse;
  }

  &--without-padding {
    padding: 0;
  }
}
